import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"

import Layout from "../../components/structural/layout"
import OTLink from "../../components/global/otLink"
import PropTypes from "prop-types"
import React from "react"
import SectionHero from "../../components/sections/sectionHero"
import Seo from "../../components/seo"
import SmartText from "../../utils/TextHandler"
import { format } from "date-fns"
import { graphql } from "gatsby"
import HorizontalRule from "../../components/instances/horizontalRule"
import * as styles from "./newsPost.module.css"

const NewsPost = ({ data, pageContext }) => {
  const story = useStoryblokState(data?.page ? data.page : pageContext.story)

  const { content } = story
  const {
    date,
    author,
    title,
    text_content,
    desktop_article_image,
    mobile_article_image,
  } = content

  return (
    <Layout>
      <div {...storyblokEditable(content)}>
        <SectionHero
          blok={{
            desktop_image: desktop_article_image,
            mobile_image: mobile_article_image,
          }}
        />
        <section className="py-16">
          <div className="container max-w-[1024px] mx-auto px-4 md:px-8 text-center">
            {title && <h1 className="mb-12">{title}</h1>}
            <div className="mb-12">
              <HorizontalRule />
            </div>
            {date && (
              <p className="se-smallprint uppercase text-se-grey mb-0">
                Posted on {format(new Date(date), "d MMMM, yyyy")}
                {author && ` by ${author}`}
              </p>
            )}
          </div>
        </section>

        {text_content && (
          <section className="pb-16">
            <div className="container max-w-[1024px] mx-auto px-4 md:px-8">
              <SmartText
                containerClassName={`se-body-regular ${styles.newspostText}`}
              >
                {text_content}
              </SmartText>
            </div>
          </section>
        )}

        <section className="py-16 bg-se-black text-se-white">
          <div className="container max-w-[1024px] mx-auto px-4 md:px-8 text-center">
            <OTLink
              button="border"
              icon="keyboard_double_arrow_left"
              iconPosition="start"
              to="/news"
            >
              Back to News
            </OTLink>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default NewsPost

NewsPost.defaultProps = {
  data: null,
  pageContext: null,
}

NewsPost.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const Head = ({ data, pageContext }) => {
  const story = useStoryblokState(data.page || pageContext.story)

  const { seo, seo_author, seo_image, seo_keywords } = story.content

  const siteSettings = JSON.parse(data?.settings?.content)

  return (
    <Seo
      settings={siteSettings}
      {...seo}
      author={seo_author}
      image={seo_image}
      keywords={seo_keywords}
    />
  )
}

export const QUERY = graphql`
  query NewsPostDefaultQuery {
    settings: storyblokEntry(full_slug: { eq: "site-settings" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
      published_at
    }
  }
`
